import useTagSearch from 'gantt/hooks/gant-view/use-tag-search';
import { useTranslation } from 'react-i18next';
import TaggingSearch, {
  ISearchOption,
} from 'core/components/tagging-search/tagging-search';
import { useStatsStore } from 'statistics/store/stats.store';
import { PotTypeEnum } from 'tasks-api';

export const StatsSearch = () => {
  const { t } = useTranslation();

  const { setSelectedPot, setPageNumber, setPotType } = useStatsStore();

  const [search, { data }] = useTagSearch();

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.code === 'Enter') {
      e.stopPropagation();
    }
  };

  return (
    <TaggingSearch
      placeholder={t('project_statistics.search_placeholder')}
      showTags={false}
      freeSolo
      onChange={(_, __, ___, details) => {
        if (
          details?.option.type === 'project' ||
          details?.option.label === 'ticket'
        ) {
          setPotType(PotTypeEnum.Project);
          setSelectedPot(details.option.label);
          setPageNumber(1);
        }
        if (details?.option.type === 'tag') {
          setPotType('tag');
          setSelectedPot(details.option.label);
          setPageNumber(1);
        }
        if (details?.option.type === 'ticket') {
          setPotType(PotTypeEnum.Ticket);
          setSelectedPot(details.option.label);
          setPageNumber(1);
        }
      }}
      // Stop enter from loosing focus. It doesn't need to search.
      onKeyDown={handleKeyDown}
      options={data?.map((item) => item as unknown as ISearchOption) ?? []}
      onInputChange={(event, value) => {
        search(value);
      }}
    />
  );
};
