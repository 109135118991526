import { QueryFunctionContext, useQuery } from 'react-query';
import { api } from 'core/services/api.service';
import { statisticsKeys } from 'statistics/queries/statistics.keys';

type IndexStatistics = ReturnType<typeof statisticsKeys['indexProjectStats']>;

const indexProjectStats = async ({
  queryKey: [{ potTypeName }],
}: QueryFunctionContext<IndexStatistics>) => {
  const { data } = await api.potsService.indexTaskmanagerPot({
    params: {
      limit: 10000,
      'pot_type@name': potTypeName,
    },
  });
  return data;
};

export const useIndexProjectStats = (queryKey: IndexStatistics) => {
  const [{ enabled }] = queryKey;

  return useQuery(queryKey, indexProjectStats, {
    enabled,
  });
};
