import { PotTypeEnum } from 'tasks-api';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface IStatsStore {
  selectedPot: string;
  potType: PotTypeEnum | string;
  pageNumber: number;
  setSelectedPot: (search: string) => void;
  setPotType: (potType: PotTypeEnum | string) => void;
  setPageNumber: (pageNumber: number) => void;
}

export const useStatsStore = create<IStatsStore>()(
  devtools(
    (set) => ({
      selectedPot: '',
      potType: PotTypeEnum.Ticket,
      pageNumber: 1,
      setSelectedPot: (selectedPot) => {
        set(
          {
            selectedPot,
          },
          false,
          'stats-store__setSelectedPot',
        );
      },
      setPotType: (potType) => {
        set(
          {
            potType,
          },
          false,
          'stats-store__setPotType',
        );
      },
      setPageNumber: (pageNumber) => {
        set(
          {
            pageNumber,
          },
          false,
          'stats-store__setPageNumber',
        );
      },
    }),
    {
      name: 'stats-store',
    },
  ),
);
