import { SyntheticEvent, useState } from 'react';
import { Autocomplete, Chip } from '@mui/material';
import * as React from 'react';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/base';
import { TaskmanagerSearchDto, TaskmanagerSearchReturnDto } from 'gateway-api';

import * as S from 'core/components/tagging-search/tagging-search.styles';

export interface ISearchOption extends TaskmanagerSearchReturnDto {
  pot?: TaskmanagerSearchDto;
}

interface ISearchProps {
  name?: string;
  onSubmit?: (tags: string) => void;
  placeholder?: string;
  options?: ISearchOption[];
  onInputChange?: (event: SyntheticEvent, value: string) => void;
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: ISearchOption[],
  ) => void;
  onChange?: (
    event: React.SyntheticEvent,
    value: ISearchOption[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ISearchOption>,
  ) => void;
  freeSolo?: boolean;
  showTags?: boolean;
  id?: string;
}
const TaggingSearch = ({
  onSubmit,
  options,
  name,
  showTags = true,
  onInputChange,
  onChange,
  onKeyDown,
  freeSolo = false,
  placeholder,
}: ISearchProps): JSX.Element => {
  const [search, setSearch] = useState<ISearchOption[]>([]);

  const handleClick = () => {
    if (onSubmit) {
      onSubmit(search.map((item) => item.id).join(','));
    }
  };

  return (
    <S.AutoCompleteContainer>
      <Autocomplete<ISearchOption, boolean, boolean, boolean>
        multiple
        onInputChange={onInputChange}
        freeSolo={freeSolo}
        disableClearable
        options={options ?? []}
        onChange={(e, newValue, reason, details) => {
          if (onChange) {
            onChange(e, newValue as ISearchOption[], reason, details);
          }
          setSearch(newValue as ISearchOption[]);
        }}
        renderTags={(value, getTagProps) => {
          if (showTags) {
            return value.map((option, index) => (
              <Chip
                variant='outlined'
                label={option.label}
                {...getTagProps({ index })}
                key={option.id}
              />
            ));
          }
          return null;
        }}
        renderInput={(params) => (
          <>
            <S.Input
              {...params}
              label={name}
              value={search}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                onKeyDown: (e) => {
                  if (onKeyDown) {
                    onKeyDown(e, search);
                  }
                },
              }}
            />
            <S.SearchIcon onClick={handleClick} />
          </>
        )}
      />
    </S.AutoCompleteContainer>
  );
};

export default TaggingSearch;
