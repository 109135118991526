import { Typography } from '@mui/material';
import TaskCard from 'statistics/components/task-card/task-card';
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent, useState } from 'react';
import CustomHelmet from 'core/components/custom-helmet';
import Scaffold from 'core/components/scaffolds/scaffold';
import { useTranslation } from 'react-i18next';
import TaskDetailsStats from 'statistics/components/task-details-statistics/task-details-statistics';
import { StatsSearch } from 'statistics/components/stats-search';
import { TaskmanagerPotDto } from 'gateway-api';
import { useStatsStore } from 'statistics/store/stats.store';
import { useIndexTicketStats } from 'statistics/queries/use-index-ticket-stats';
import { PotTypeEnum } from 'tasks-api';
import { statisticsKeys } from 'statistics/queries/statistics.keys';
import { useIndexProjectStats } from 'statistics/queries/use-index-project-stats';

import * as S from 'statistics/pages/project-statistics/project-statistics.style';

const ProjectStatistics = () => {
  const {
    potType,
    selectedPot,
    setPotType,
    setSelectedPot,
    setPageNumber,
    pageNumber,
  } = useStatsStore();

  const { data: tickets } = useIndexTicketStats(
    statisticsKeys.indexTicketStats({
      potTypeName: potType,
      selectedPotName: selectedPot,
      pageNumber,
    }),
  );

  const { data: projects } = useIndexProjectStats(
    statisticsKeys.indexProjectStats({
      potTypeName: potType,
      enabled: Boolean(potType === PotTypeEnum.Project),
    }),
  );

  const selectedProject = projects?.items.find(
    (project) => project.name === selectedPot && project.id,
  );

  const { t } = useTranslation();

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const [selectedTask, setSelectedTask] = useState<TaskmanagerPotDto>();
  const [taskOpen, setTaskOpen] = useState(false);
  const [hideTasks, setHideTasks] = useState(false);

  const handleClick = (task?: TaskmanagerPotDto) => {
    if (task) {
      setSelectedTask(task);
      setTaskOpen(true);
      setHideTasks(true);
    }
  };

  const handleBack = () => {
    setTaskOpen(false);
    setHideTasks(true);
  };

  const handleRemoveTag = () => {
    setSelectedPot('');
    setPageNumber(1);
    setPotType(PotTypeEnum.Ticket);
  };

  return (
    <>
      <CustomHelmet pageName={t('project_statistics.title')} />
      <Scaffold
        headerTitle={t('project_statistics.title')}
        pageTitle={t('project_statistics.title')}
      >
        <S.FlexContainer>
          <S.Tasks mobileView={hideTasks}>
            <S.StyledSearch>
              <StatsSearch />
            </S.StyledSearch>

            {potType === PotTypeEnum.Project && selectedPot && (
              <div>
                <S.StyledChip
                  label={selectedPot}
                  onDelete={handleRemoveTag}
                  variant='outlined'
                />

                <S.StyledTaskCard
                  selectedTicket={selectedProject === selectedTask}
                  key={selectedPot}
                  onClick={() => handleClick(selectedProject)}
                >
                  <S.StyledTaskCardContainer>
                    <Typography fontSize={20}>{selectedPot}</Typography>
                  </S.StyledTaskCardContainer>
                </S.StyledTaskCard>
              </div>
            )}

            {tickets?.items?.length && (
              <>
                <S.TaskList>
                  {tickets.items?.map(
                    (ticket) =>
                      ticket.pot_type.name === PotTypeEnum.Ticket && (
                        <S.StyledTaskCard
                          selectedTicket={ticket === selectedTask}
                          key={ticket.id}
                          onClick={() => handleClick(ticket)}
                        >
                          <TaskCard pot={ticket} />
                        </S.StyledTaskCard>
                      ),
                  )}
                </S.TaskList>
                {tickets?.pagination?.page_total > 1 && (
                  <S.StyledPagination
                    count={tickets?.pagination?.page_total}
                    page={tickets?.pagination.page_current}
                    onChange={handlePageChange}
                  />
                )}
              </>
            )}
          </S.Tasks>
          {tickets?.items?.length ? (
            <S.TaskDetails>
              {taskOpen && (
                <>
                  <S.BackButton onClick={handleBack} variant='text'>
                    {t('project_statistics.selectedPot_placeholder')}
                  </S.BackButton>
                  {selectedTask && <TaskDetailsStats pot={selectedTask} />}
                </>
              )}
            </S.TaskDetails>
          ) : (
            <S.NoTasks>
              <SearchIcon />
              <Typography>{t('project_statistics.no_task_body')}</Typography>
              <Typography>{t('project_statistics.no_task_body_1')}</Typography>
            </S.NoTasks>
          )}
        </S.FlexContainer>
      </Scaffold>
    </>
  );
};

export default ProjectStatistics;
