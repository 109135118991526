/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import DateRangeType from 'date-range/models/date-range-type.enum';
import { useDateRange } from 'date-range/store/date-range';
import { DateTime } from 'luxon';
import useEventTypeProvider, {
  PageEnum,
} from 'schedule/core/providers/event-type-provider';
import { UserDayDropArea } from 'schedule/core/components/user-day-drop-area/user-day-drop-area';
import { UserEvent } from 'schedule/core/components/user-event/user-event';
import * as S from 'schedule/core/components/user-day/user-day.styles';
import { useMemo } from 'react';
import Fireworks from 'core/components/fireworks-animation/fireworks-animation';
import { TaskmanagerEventTypeEnum, TaskmanagerScheduleDto } from 'gateway-api';
import { startCase } from 'lodash';

interface IUserDay {
  index: number;
  date: DateTime;
  width: number;
  userSchedule: TaskmanagerScheduleDto;
  eventType: TaskmanagerEventTypeEnum;
}

export const UserDay = ({
  index,
  date,
  userSchedule,
  width,
  eventType,
}: IUserDay) => {
  const userDayKey = `${userSchedule.user.id}_${date.toString()}`;
  const {
    state: { dateRangeType },
  } = useDateRange();

  const { scheduleParams, page } = useEventTypeProvider();

  const availableHours = userSchedule?.user?.profile?.available_hours ?? 7.5;

  const isYear = dateRangeType === DateRangeType.year;

  const listItemPadding = 9.5;

  // If dateRangeType is year then don't set different style for weekend.
  // Start of year could be a weekend which would set every cell to the weekend style.
  const isWeekend = !isYear && (date.weekday === 7 || date.weekday === 6);

  // The current iterating date formatted to "1970-01-01"
  const scheduleDate = date.toFormat('yyyy-MM-dd');

  // Try and find the date entry within the users schedule for the iterating date.
  let dateEntry = userSchedule.entries.find((entry) => {
    let dateFrom = DateTime.fromJSDate(new Date(entry.date_from), {zone: "Europe/London"});
    if (isYear) {
      dateFrom = dateFrom.startOf('week');
    }
    return dateFrom.toFormat('yyyy-MM-dd') === scheduleDate;
  });

  // calculate available hours per event type

  // If no date entry exists in the users schedule for this date then show the default.
  if (!dateEntry) {
    dateEntry = {
      hours_remaining_by_type: {},
      hours_remaining: availableHours,
      date_from: scheduleDate,
      events: [],
    };
  }

  const holidays = dateEntry.events.filter(
    (event) => event?.pots_by_type?.public_holiday !== undefined,
  );

  const pageValidation: boolean = useMemo(() => {
    if (page === PageEnum.ADD_TIME) {
      // return scheduleParams.eventType === EventTypeEnum.Complete;
      return scheduleParams.eventType[0] === TaskmanagerEventTypeEnum.Complete;
    }

    return true;
  }, [page, scheduleParams.eventType]);

  return (
    <S.ScheduleItem
      key={userDayKey}
      padding={listItemPadding}
      $dateRangeType={dateRangeType}
      $showDate={dateRangeType === DateRangeType.day ? false : index === 0}
      $isWeekend={isWeekend}
    >
      {page === PageEnum.MULTI_VIEW && date.weekday === 1 && (
        <S.TableHeadParagraph fontWeight={500}>
          {eventType === TaskmanagerEventTypeEnum.Complete
            ? 'Recorded'
            : startCase(eventType)}
        </S.TableHeadParagraph>
      )}
      <S.ScheduleDroppableContainer>
        {/* {pageValidation && (
        <S.AnimationContainer
          id={
            dateEntry?.hours_remaining === 0
              ? "end-animation"
              : "no-end-animation"
          }
        />
      )} */}
        {pageValidation &&
          dateEntry?.hours_remaining === 0 &&
          dateEntry.date_from === DateTime.now().toFormat('yyyy-MM-dd') && (
            <Fireworks />
          )}
        {/* Events should not be dropped on a weekend */}
        {!isWeekend && page === PageEnum.MULTI_VIEW ? (
          <UserDayDropArea
            eventType={eventType}
            index={index}
            droppableId={`${userDayKey}_${eventType}`}
            remainingTime={
              (dateEntry && dateEntry.hours_remaining_by_type[eventType]) ??
              dateEntry.hours_remaining_by_type[
                TaskmanagerEventTypeEnum.Static
              ] ??
              7.5
            }
            date={scheduleDate}
            userId={userSchedule.user.id}
            isDisabled={holidays.length > 0}
          >
            {dateEntry &&
              dateEntry.events
                .filter(
                  (event) =>
                    event.event_type === eventType ||
                    event.event_type === TaskmanagerEventTypeEnum.Static,
                )
                .map((event) => (
                  <UserEvent
                    key={event.id}
                    event={event}
                    availableHours={availableHours}
                    containerWidth={width - listItemPadding * 2}
                  />
                ))}
          </UserDayDropArea>
        ) : (
          <UserDayDropArea
            eventType={eventType}
            index={index}
            droppableId={`${userDayKey}_${eventType}`}
            remainingTime={(dateEntry && dateEntry.hours_remaining) ?? 7.5}
            date={scheduleDate}
            userId={userSchedule.user.id}
            isDisabled={holidays.length > 0}
          >
            {dateEntry &&
              dateEntry.events.map((event) => (
                <UserEvent
                  key={event.id}
                  event={event}
                  availableHours={availableHours}
                  containerWidth={width - listItemPadding * 2}
                />
              ))}
          </UserDayDropArea>
        )}
      </S.ScheduleDroppableContainer>
    </S.ScheduleItem>
  );
};

//  .filter((event) => event.event_type === eventType)
