interface IIndexPotStats {
  potTypeName?: string;
  pageNumber?: number;
  selectedPotName?: string;
  enabled?: boolean;
}

export const statisticsKeys = {
  base: [{ scope: 'statistics' }] as const,
  index: () =>
    [
      {
        ...statisticsKeys.base[0],
        entity: 'index',
      },
    ] as const,
  indexTicketStats: (query: IIndexPotStats) =>
    [
      {
        ...statisticsKeys.base[0],
        ...query,
      },
    ] as const,
  indexProjectStats: (query: IIndexPotStats) =>
    [
      {
        ...statisticsKeys.base[0],
        ...query,
      },
    ] as const,
};
