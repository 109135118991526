import styled from '@emotion/styled/macro';
import { Search as MuiSearchIcon } from '@mui/icons-material';
import { TextField } from '@mui/material';

export const SearchIcon = styled(MuiSearchIcon)`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: -10px;
  transform: translate(-50%, -50%);
  font-size: 30px;
`;

export const Input = styled(TextField)`
  && {
    border-color: ${({ theme }) => theme.colors.borderGrey};

    .MuiAutocomplete-root {
      position: relative;
    }

    .MuiAutocomplete-tag {
      background-color: #a0b1d3;
      order: 1;
    }

    .MuiInputBase-input {
      order: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 1px solid #053349;
      transition: width 0.4s ease;
      background: none;
      box-sizing: border-box;
      padding: 10px;
    }

    .MuiOutlinedInput-root {
      display: flex;
      padding: 0;
    }

    .MuiChip-deleteIcon {
      color: #07334a;
    }

    .MuiInputBase-input {
      padding: 10px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiAutocomplete-endAdornment {
      right: 38px;
      top: 5px;
    }
  }
`;

export const AutoCompleteContainer = styled.div`
  && {
    .MuiAutocomplete-root {
      position: relative;
    }
  }
`;
