"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Recite Me Tasks Service (Testing)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = exports.TagApi = exports.TagApiFactory = exports.TagApiFp = exports.TagApiAxiosParamCreator = exports.SkillApi = exports.SkillApiFactory = exports.SkillApiFp = exports.SkillApiAxiosParamCreator = exports.SearchApi = exports.SearchApiFactory = exports.SearchApiFp = exports.SearchApiAxiosParamCreator = exports.ScheduleApi = exports.ScheduleApiFactory = exports.ScheduleApiFp = exports.ScheduleApiAxiosParamCreator = exports.SalaryBandApi = exports.SalaryBandApiFactory = exports.SalaryBandApiFp = exports.SalaryBandApiAxiosParamCreator = exports.ProfileApi = exports.ProfileApiFactory = exports.ProfileApiFp = exports.ProfileApiAxiosParamCreator = exports.PotTypeApi = exports.PotTypeApiFactory = exports.PotTypeApiFp = exports.PotTypeApiAxiosParamCreator = exports.PotStatApi = exports.PotStatApiFactory = exports.PotStatApiFp = exports.PotStatApiAxiosParamCreator = exports.PotGanttApi = exports.PotGanttApiFactory = exports.PotGanttApiFp = exports.PotGanttApiAxiosParamCreator = exports.PotApi = exports.PotApiFactory = exports.PotApiFp = exports.PotApiAxiosParamCreator = exports.NoteApi = exports.NoteApiFactory = exports.NoteApiFp = exports.NoteApiAxiosParamCreator = exports.JiraProjectApi = exports.JiraProjectApiFactory = exports.JiraProjectApiFp = exports.JiraProjectApiAxiosParamCreator = exports.JiraIssueApi = exports.JiraIssueApiFactory = exports.JiraIssueApiFp = exports.JiraIssueApiAxiosParamCreator = exports.EventTypeApi = exports.EventTypeApiFactory = exports.EventTypeApiFp = exports.EventTypeApiAxiosParamCreator = exports.EventApi = exports.EventApiFactory = exports.EventApiFp = exports.EventApiAxiosParamCreator = exports.AttachmentApi = exports.AttachmentApiFactory = exports.AttachmentApiFp = exports.AttachmentApiAxiosParamCreator = exports.PotTypeEnum = exports.EventTypeEnum = exports.EventRecurEnum = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 * Available event recurring types.
 * @export
 * @enum {string}
 */
var EventRecurEnum;
(function (EventRecurEnum) {
    EventRecurEnum["Null"] = "null";
    EventRecurEnum["Daily"] = "daily";
    EventRecurEnum["Weekly"] = "weekly";
})(EventRecurEnum = exports.EventRecurEnum || (exports.EventRecurEnum = {}));
/**
 * Available event type strings.
 * @export
 * @enum {string}
 */
var EventTypeEnum;
(function (EventTypeEnum) {
    EventTypeEnum["Static"] = "static";
    EventTypeEnum["Estimate"] = "estimate";
    EventTypeEnum["Schedule"] = "schedule";
    EventTypeEnum["Complete"] = "complete";
})(EventTypeEnum = exports.EventTypeEnum || (exports.EventTypeEnum = {}));
/**
 * Available event type strings.
 * @export
 * @enum {string}
 */
var PotTypeEnum;
(function (PotTypeEnum) {
    PotTypeEnum["Time"] = "time";
    PotTypeEnum["Category"] = "category";
    PotTypeEnum["Project"] = "project";
    PotTypeEnum["Ticket"] = "ticket";
    PotTypeEnum["CalendarEvent"] = "calendar_event";
    PotTypeEnum["Leave"] = "leave";
    PotTypeEnum["LeaveBooking"] = "leave_booking";
    PotTypeEnum["PublicHoliday"] = "public_holiday";
})(PotTypeEnum = exports.PotTypeEnum || (exports.PotTypeEnum = {}));
/**
 * AttachmentApi - axios parameter creator
 * @export
 */
exports.AttachmentApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Destroys a single Attachment resource.
         * @param {number} id The id of the Attachment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyAttachment: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('destroyAttachment', 'id', id);
                    localVarPath = "/attachments/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a listing of Attachment resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexAttachment: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/attachments";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single Attachment resource.
         * @param {number} id The id of the Attachment.
         * @param {AttachmentPatch} body A single Attachment resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAttachment: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('patchAttachment', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('patchAttachment', 'body', body);
                    localVarPath = "/attachments/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single Attachment resource by id.
         * @param {number} id The id of the Attachment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showAttachment: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showAttachment', 'id', id);
                    localVarPath = "/attachments/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single Attachment resource.
         * @param {AttachmentEdit} body A single Attachment resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeAttachment: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('storeAttachment', 'body', body);
                    localVarPath = "/attachments";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Attachment resource.
         * @param {number} id The id of the Attachment.
         * @param {AttachmentEdit} body A single Attachment resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAttachment: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('updateAttachment', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('updateAttachment', 'body', body);
                    localVarPath = "/attachments/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * AttachmentApi - functional programming interface
 * @export
 */
exports.AttachmentApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.AttachmentApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Destroys a single Attachment resource.
         * @param {number} id The id of the Attachment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyAttachment: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.destroyAttachment(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a listing of Attachment resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexAttachment: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexAttachment(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single Attachment resource.
         * @param {number} id The id of the Attachment.
         * @param {AttachmentPatch} body A single Attachment resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAttachment: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchAttachment(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single Attachment resource by id.
         * @param {number} id The id of the Attachment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showAttachment: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showAttachment(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single Attachment resource.
         * @param {AttachmentEdit} body A single Attachment resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeAttachment: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.storeAttachment(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Attachment resource.
         * @param {number} id The id of the Attachment.
         * @param {AttachmentEdit} body A single Attachment resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAttachment: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateAttachment(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * AttachmentApi - factory interface
 * @export
 */
exports.AttachmentApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.AttachmentApiFp(configuration);
    return {
        /**
         *
         * @summary Destroys a single Attachment resource.
         * @param {number} id The id of the Attachment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyAttachment: function (id, options) {
            return localVarFp.destroyAttachment(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a listing of Attachment resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexAttachment: function (options) {
            return localVarFp.indexAttachment(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Modifies (parts of) a single Attachment resource.
         * @param {number} id The id of the Attachment.
         * @param {AttachmentPatch} body A single Attachment resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAttachment: function (id, body, options) {
            return localVarFp.patchAttachment(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single Attachment resource by id.
         * @param {number} id The id of the Attachment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showAttachment: function (id, options) {
            return localVarFp.showAttachment(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Creates a new instance of a single Attachment resource.
         * @param {AttachmentEdit} body A single Attachment resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeAttachment: function (body, options) {
            return localVarFp.storeAttachment(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Attachment resource.
         * @param {number} id The id of the Attachment.
         * @param {AttachmentEdit} body A single Attachment resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAttachment: function (id, body, options) {
            return localVarFp.updateAttachment(id, body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * AttachmentApi - object-oriented interface
 * @export
 * @class AttachmentApi
 * @extends {BaseAPI}
 */
var AttachmentApi = /** @class */ (function (_super) {
    __extends(AttachmentApi, _super);
    function AttachmentApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Destroys a single Attachment resource.
     * @param {number} id The id of the Attachment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    AttachmentApi.prototype.destroyAttachment = function (id, options) {
        var _this = this;
        return exports.AttachmentApiFp(this.configuration).destroyAttachment(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a listing of Attachment resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    AttachmentApi.prototype.indexAttachment = function (options) {
        var _this = this;
        return exports.AttachmentApiFp(this.configuration).indexAttachment(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Modifies (parts of) a single Attachment resource.
     * @param {number} id The id of the Attachment.
     * @param {AttachmentPatch} body A single Attachment resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    AttachmentApi.prototype.patchAttachment = function (id, body, options) {
        var _this = this;
        return exports.AttachmentApiFp(this.configuration).patchAttachment(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single Attachment resource by id.
     * @param {number} id The id of the Attachment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    AttachmentApi.prototype.showAttachment = function (id, options) {
        var _this = this;
        return exports.AttachmentApiFp(this.configuration).showAttachment(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Creates a new instance of a single Attachment resource.
     * @param {AttachmentEdit} body A single Attachment resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    AttachmentApi.prototype.storeAttachment = function (body, options) {
        var _this = this;
        return exports.AttachmentApiFp(this.configuration).storeAttachment(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Replaces (or updates all data of) a single Attachment resource.
     * @param {number} id The id of the Attachment.
     * @param {AttachmentEdit} body A single Attachment resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApi
     */
    AttachmentApi.prototype.updateAttachment = function (id, body, options) {
        var _this = this;
        return exports.AttachmentApiFp(this.configuration).updateAttachment(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AttachmentApi;
}(base_1.BaseAPI));
exports.AttachmentApi = AttachmentApi;
/**
 * EventApi - axios parameter creator
 * @export
 */
exports.EventApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Clones an existing instance of a single Event resource with optional fields replaced.
         * @param {string} id The uuid of the Event.
         * @param {EventPatch} body A single Event resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneEvent: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('cloneEvent', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('cloneEvent', 'body', body);
                    localVarPath = "/events/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Destroys a single Event resource.
         * @param {string} id The uuid of the Event.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyEvent: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('destroyEvent', 'id', id);
                    localVarPath = "/events/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a listing of Event resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexEvent: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/events";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single Event resource.
         * @param {string} id The uuid of the Event.
         * @param {EventPatch} body A single Event resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEvent: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('patchEvent', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('patchEvent', 'body', body);
                    localVarPath = "/events/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single Event resource by id.
         * @param {string} id The uuid of the Event.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showEvent: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showEvent', 'id', id);
                    localVarPath = "/events/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single Event resource.
         * @param {EventEdit} body A single Event resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeEvent: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('storeEvent', 'body', body);
                    localVarPath = "/events";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Event resource.
         * @param {string} id The uuid of the Event.
         * @param {EventEdit} body A single Event resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('updateEvent', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('updateEvent', 'body', body);
                    localVarPath = "/events/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * EventApi - functional programming interface
 * @export
 */
exports.EventApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.EventApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Clones an existing instance of a single Event resource with optional fields replaced.
         * @param {string} id The uuid of the Event.
         * @param {EventPatch} body A single Event resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneEvent: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cloneEvent(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Destroys a single Event resource.
         * @param {string} id The uuid of the Event.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyEvent: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.destroyEvent(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a listing of Event resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexEvent: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexEvent(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single Event resource.
         * @param {string} id The uuid of the Event.
         * @param {EventPatch} body A single Event resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEvent: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchEvent(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single Event resource by id.
         * @param {string} id The uuid of the Event.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showEvent: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showEvent(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single Event resource.
         * @param {EventEdit} body A single Event resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeEvent: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.storeEvent(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Event resource.
         * @param {string} id The uuid of the Event.
         * @param {EventEdit} body A single Event resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEvent(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * EventApi - factory interface
 * @export
 */
exports.EventApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.EventApiFp(configuration);
    return {
        /**
         *
         * @summary Clones an existing instance of a single Event resource with optional fields replaced.
         * @param {string} id The uuid of the Event.
         * @param {EventPatch} body A single Event resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneEvent: function (id, body, options) {
            return localVarFp.cloneEvent(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Destroys a single Event resource.
         * @param {string} id The uuid of the Event.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyEvent: function (id, options) {
            return localVarFp.destroyEvent(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a listing of Event resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexEvent: function (options) {
            return localVarFp.indexEvent(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Modifies (parts of) a single Event resource.
         * @param {string} id The uuid of the Event.
         * @param {EventPatch} body A single Event resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEvent: function (id, body, options) {
            return localVarFp.patchEvent(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single Event resource by id.
         * @param {string} id The uuid of the Event.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showEvent: function (id, options) {
            return localVarFp.showEvent(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Creates a new instance of a single Event resource.
         * @param {EventEdit} body A single Event resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeEvent: function (body, options) {
            return localVarFp.storeEvent(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Event resource.
         * @param {string} id The uuid of the Event.
         * @param {EventEdit} body A single Event resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent: function (id, body, options) {
            return localVarFp.updateEvent(id, body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
var EventApi = /** @class */ (function (_super) {
    __extends(EventApi, _super);
    function EventApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Clones an existing instance of a single Event resource with optional fields replaced.
     * @param {string} id The uuid of the Event.
     * @param {EventPatch} body A single Event resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    EventApi.prototype.cloneEvent = function (id, body, options) {
        var _this = this;
        return exports.EventApiFp(this.configuration).cloneEvent(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Destroys a single Event resource.
     * @param {string} id The uuid of the Event.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    EventApi.prototype.destroyEvent = function (id, options) {
        var _this = this;
        return exports.EventApiFp(this.configuration).destroyEvent(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a listing of Event resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    EventApi.prototype.indexEvent = function (options) {
        var _this = this;
        return exports.EventApiFp(this.configuration).indexEvent(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Modifies (parts of) a single Event resource.
     * @param {string} id The uuid of the Event.
     * @param {EventPatch} body A single Event resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    EventApi.prototype.patchEvent = function (id, body, options) {
        var _this = this;
        return exports.EventApiFp(this.configuration).patchEvent(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single Event resource by id.
     * @param {string} id The uuid of the Event.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    EventApi.prototype.showEvent = function (id, options) {
        var _this = this;
        return exports.EventApiFp(this.configuration).showEvent(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Creates a new instance of a single Event resource.
     * @param {EventEdit} body A single Event resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    EventApi.prototype.storeEvent = function (body, options) {
        var _this = this;
        return exports.EventApiFp(this.configuration).storeEvent(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Replaces (or updates all data of) a single Event resource.
     * @param {string} id The uuid of the Event.
     * @param {EventEdit} body A single Event resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    EventApi.prototype.updateEvent = function (id, body, options) {
        var _this = this;
        return exports.EventApiFp(this.configuration).updateEvent(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return EventApi;
}(base_1.BaseAPI));
exports.EventApi = EventApi;
/**
 * EventTypeApi - axios parameter creator
 * @export
 */
exports.EventTypeApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Destroys a single EventType resource.
         * @param {number} id The id of the Event Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyEventType: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('destroyEventType', 'id', id);
                    localVarPath = "/event-types/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a listing of EventType resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexEventType: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/event-types";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single EventType resource.
         * @param {number} id The id of the Event Type.
         * @param {EventTypePatch} body A single EventType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEventType: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('patchEventType', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('patchEventType', 'body', body);
                    localVarPath = "/event-types/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single EventType resource by id.
         * @param {number} id The id of the Event Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showEventType: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showEventType', 'id', id);
                    localVarPath = "/event-types/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single EventType resource.
         * @param {EventTypeEdit} body A single EventType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeEventType: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('storeEventType', 'body', body);
                    localVarPath = "/event-types";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single EventType resource.
         * @param {number} id The id of the Event Type.
         * @param {EventTypeEdit} body A single EventType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventType: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('updateEventType', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('updateEventType', 'body', body);
                    localVarPath = "/event-types/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * EventTypeApi - functional programming interface
 * @export
 */
exports.EventTypeApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.EventTypeApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Destroys a single EventType resource.
         * @param {number} id The id of the Event Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyEventType: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.destroyEventType(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a listing of EventType resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexEventType: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexEventType(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single EventType resource.
         * @param {number} id The id of the Event Type.
         * @param {EventTypePatch} body A single EventType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEventType: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchEventType(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single EventType resource by id.
         * @param {number} id The id of the Event Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showEventType: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showEventType(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single EventType resource.
         * @param {EventTypeEdit} body A single EventType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeEventType: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.storeEventType(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single EventType resource.
         * @param {number} id The id of the Event Type.
         * @param {EventTypeEdit} body A single EventType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventType: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateEventType(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * EventTypeApi - factory interface
 * @export
 */
exports.EventTypeApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.EventTypeApiFp(configuration);
    return {
        /**
         *
         * @summary Destroys a single EventType resource.
         * @param {number} id The id of the Event Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyEventType: function (id, options) {
            return localVarFp.destroyEventType(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a listing of EventType resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexEventType: function (options) {
            return localVarFp.indexEventType(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Modifies (parts of) a single EventType resource.
         * @param {number} id The id of the Event Type.
         * @param {EventTypePatch} body A single EventType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchEventType: function (id, body, options) {
            return localVarFp.patchEventType(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single EventType resource by id.
         * @param {number} id The id of the Event Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showEventType: function (id, options) {
            return localVarFp.showEventType(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Creates a new instance of a single EventType resource.
         * @param {EventTypeEdit} body A single EventType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeEventType: function (body, options) {
            return localVarFp.storeEventType(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single EventType resource.
         * @param {number} id The id of the Event Type.
         * @param {EventTypeEdit} body A single EventType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventType: function (id, body, options) {
            return localVarFp.updateEventType(id, body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * EventTypeApi - object-oriented interface
 * @export
 * @class EventTypeApi
 * @extends {BaseAPI}
 */
var EventTypeApi = /** @class */ (function (_super) {
    __extends(EventTypeApi, _super);
    function EventTypeApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Destroys a single EventType resource.
     * @param {number} id The id of the Event Type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypeApi
     */
    EventTypeApi.prototype.destroyEventType = function (id, options) {
        var _this = this;
        return exports.EventTypeApiFp(this.configuration).destroyEventType(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a listing of EventType resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypeApi
     */
    EventTypeApi.prototype.indexEventType = function (options) {
        var _this = this;
        return exports.EventTypeApiFp(this.configuration).indexEventType(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Modifies (parts of) a single EventType resource.
     * @param {number} id The id of the Event Type.
     * @param {EventTypePatch} body A single EventType resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypeApi
     */
    EventTypeApi.prototype.patchEventType = function (id, body, options) {
        var _this = this;
        return exports.EventTypeApiFp(this.configuration).patchEventType(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single EventType resource by id.
     * @param {number} id The id of the Event Type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypeApi
     */
    EventTypeApi.prototype.showEventType = function (id, options) {
        var _this = this;
        return exports.EventTypeApiFp(this.configuration).showEventType(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Creates a new instance of a single EventType resource.
     * @param {EventTypeEdit} body A single EventType resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypeApi
     */
    EventTypeApi.prototype.storeEventType = function (body, options) {
        var _this = this;
        return exports.EventTypeApiFp(this.configuration).storeEventType(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Replaces (or updates all data of) a single EventType resource.
     * @param {number} id The id of the Event Type.
     * @param {EventTypeEdit} body A single EventType resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventTypeApi
     */
    EventTypeApi.prototype.updateEventType = function (id, body, options) {
        var _this = this;
        return exports.EventTypeApiFp(this.configuration).updateEventType(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return EventTypeApi;
}(base_1.BaseAPI));
exports.EventTypeApi = EventTypeApi;
/**
 * JiraIssueApi - axios parameter creator
 * @export
 */
exports.JiraIssueApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Returns a listing of JiraIssue resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexJiraIssue: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/jira-issues";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single JiraIssue resource by id.
         * @param {string} id The id or key of the Jira Issue.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showJiraIssue: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showJiraIssue', 'id', id);
                    localVarPath = "/jira-issues/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * JiraIssueApi - functional programming interface
 * @export
 */
exports.JiraIssueApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.JiraIssueApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Returns a listing of JiraIssue resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexJiraIssue: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexJiraIssue(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single JiraIssue resource by id.
         * @param {string} id The id or key of the Jira Issue.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showJiraIssue: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showJiraIssue(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * JiraIssueApi - factory interface
 * @export
 */
exports.JiraIssueApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.JiraIssueApiFp(configuration);
    return {
        /**
         *
         * @summary Returns a listing of JiraIssue resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexJiraIssue: function (options) {
            return localVarFp.indexJiraIssue(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single JiraIssue resource by id.
         * @param {string} id The id or key of the Jira Issue.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showJiraIssue: function (id, options) {
            return localVarFp.showJiraIssue(id, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * JiraIssueApi - object-oriented interface
 * @export
 * @class JiraIssueApi
 * @extends {BaseAPI}
 */
var JiraIssueApi = /** @class */ (function (_super) {
    __extends(JiraIssueApi, _super);
    function JiraIssueApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Returns a listing of JiraIssue resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JiraIssueApi
     */
    JiraIssueApi.prototype.indexJiraIssue = function (options) {
        var _this = this;
        return exports.JiraIssueApiFp(this.configuration).indexJiraIssue(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single JiraIssue resource by id.
     * @param {string} id The id or key of the Jira Issue.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JiraIssueApi
     */
    JiraIssueApi.prototype.showJiraIssue = function (id, options) {
        var _this = this;
        return exports.JiraIssueApiFp(this.configuration).showJiraIssue(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return JiraIssueApi;
}(base_1.BaseAPI));
exports.JiraIssueApi = JiraIssueApi;
/**
 * JiraProjectApi - axios parameter creator
 * @export
 */
exports.JiraProjectApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Destroys a single JiraProject resource.
         * @param {string} id The id or key of the Jira Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyJiraProject: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('destroyJiraProject', 'id', id);
                    localVarPath = "/jira-projects/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a listing of JiraProject resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexJiraProject: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/jira-projects";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single JiraProject resource by id.
         * @param {string} id The id or key of the Jira Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showJiraProject: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showJiraProject', 'id', id);
                    localVarPath = "/jira-projects/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * JiraProjectApi - functional programming interface
 * @export
 */
exports.JiraProjectApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.JiraProjectApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Destroys a single JiraProject resource.
         * @param {string} id The id or key of the Jira Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyJiraProject: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.destroyJiraProject(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a listing of JiraProject resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexJiraProject: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexJiraProject(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single JiraProject resource by id.
         * @param {string} id The id or key of the Jira Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showJiraProject: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showJiraProject(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * JiraProjectApi - factory interface
 * @export
 */
exports.JiraProjectApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.JiraProjectApiFp(configuration);
    return {
        /**
         *
         * @summary Destroys a single JiraProject resource.
         * @param {string} id The id or key of the Jira Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyJiraProject: function (id, options) {
            return localVarFp.destroyJiraProject(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a listing of JiraProject resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexJiraProject: function (options) {
            return localVarFp.indexJiraProject(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single JiraProject resource by id.
         * @param {string} id The id or key of the Jira Project.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showJiraProject: function (id, options) {
            return localVarFp.showJiraProject(id, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * JiraProjectApi - object-oriented interface
 * @export
 * @class JiraProjectApi
 * @extends {BaseAPI}
 */
var JiraProjectApi = /** @class */ (function (_super) {
    __extends(JiraProjectApi, _super);
    function JiraProjectApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Destroys a single JiraProject resource.
     * @param {string} id The id or key of the Jira Project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JiraProjectApi
     */
    JiraProjectApi.prototype.destroyJiraProject = function (id, options) {
        var _this = this;
        return exports.JiraProjectApiFp(this.configuration).destroyJiraProject(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a listing of JiraProject resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JiraProjectApi
     */
    JiraProjectApi.prototype.indexJiraProject = function (options) {
        var _this = this;
        return exports.JiraProjectApiFp(this.configuration).indexJiraProject(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single JiraProject resource by id.
     * @param {string} id The id or key of the Jira Project.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JiraProjectApi
     */
    JiraProjectApi.prototype.showJiraProject = function (id, options) {
        var _this = this;
        return exports.JiraProjectApiFp(this.configuration).showJiraProject(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return JiraProjectApi;
}(base_1.BaseAPI));
exports.JiraProjectApi = JiraProjectApi;
/**
 * NoteApi - axios parameter creator
 * @export
 */
exports.NoteApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Destroys a single Note resource.
         * @param {number} id The id of the Note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyNote: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('destroyNote', 'id', id);
                    localVarPath = "/notes/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a listing of Note resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexNote: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/notes";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single Note resource.
         * @param {number} id The id of the Note.
         * @param {NotePatch} body A single Note resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNote: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('patchNote', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('patchNote', 'body', body);
                    localVarPath = "/notes/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single Note resource by id.
         * @param {number} id The id of the Note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showNote: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showNote', 'id', id);
                    localVarPath = "/notes/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single Note resource.
         * @param {NoteEdit} body A single Note resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeNote: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('storeNote', 'body', body);
                    localVarPath = "/notes";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Note resource.
         * @param {number} id The id of the Note.
         * @param {NoteEdit} body A single Note resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('updateNote', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('updateNote', 'body', body);
                    localVarPath = "/notes/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * NoteApi - functional programming interface
 * @export
 */
exports.NoteApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.NoteApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Destroys a single Note resource.
         * @param {number} id The id of the Note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyNote: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.destroyNote(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a listing of Note resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexNote: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexNote(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single Note resource.
         * @param {number} id The id of the Note.
         * @param {NotePatch} body A single Note resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNote: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchNote(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single Note resource by id.
         * @param {number} id The id of the Note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showNote: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showNote(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single Note resource.
         * @param {NoteEdit} body A single Note resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeNote: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.storeNote(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Note resource.
         * @param {number} id The id of the Note.
         * @param {NoteEdit} body A single Note resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateNote(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * NoteApi - factory interface
 * @export
 */
exports.NoteApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.NoteApiFp(configuration);
    return {
        /**
         *
         * @summary Destroys a single Note resource.
         * @param {number} id The id of the Note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyNote: function (id, options) {
            return localVarFp.destroyNote(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a listing of Note resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexNote: function (options) {
            return localVarFp.indexNote(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Modifies (parts of) a single Note resource.
         * @param {number} id The id of the Note.
         * @param {NotePatch} body A single Note resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNote: function (id, body, options) {
            return localVarFp.patchNote(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single Note resource by id.
         * @param {number} id The id of the Note.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showNote: function (id, options) {
            return localVarFp.showNote(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Creates a new instance of a single Note resource.
         * @param {NoteEdit} body A single Note resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeNote: function (body, options) {
            return localVarFp.storeNote(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Note resource.
         * @param {number} id The id of the Note.
         * @param {NoteEdit} body A single Note resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote: function (id, body, options) {
            return localVarFp.updateNote(id, body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * NoteApi - object-oriented interface
 * @export
 * @class NoteApi
 * @extends {BaseAPI}
 */
var NoteApi = /** @class */ (function (_super) {
    __extends(NoteApi, _super);
    function NoteApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Destroys a single Note resource.
     * @param {number} id The id of the Note.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    NoteApi.prototype.destroyNote = function (id, options) {
        var _this = this;
        return exports.NoteApiFp(this.configuration).destroyNote(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a listing of Note resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    NoteApi.prototype.indexNote = function (options) {
        var _this = this;
        return exports.NoteApiFp(this.configuration).indexNote(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Modifies (parts of) a single Note resource.
     * @param {number} id The id of the Note.
     * @param {NotePatch} body A single Note resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    NoteApi.prototype.patchNote = function (id, body, options) {
        var _this = this;
        return exports.NoteApiFp(this.configuration).patchNote(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single Note resource by id.
     * @param {number} id The id of the Note.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    NoteApi.prototype.showNote = function (id, options) {
        var _this = this;
        return exports.NoteApiFp(this.configuration).showNote(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Creates a new instance of a single Note resource.
     * @param {NoteEdit} body A single Note resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    NoteApi.prototype.storeNote = function (body, options) {
        var _this = this;
        return exports.NoteApiFp(this.configuration).storeNote(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Replaces (or updates all data of) a single Note resource.
     * @param {number} id The id of the Note.
     * @param {NoteEdit} body A single Note resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    NoteApi.prototype.updateNote = function (id, body, options) {
        var _this = this;
        return exports.NoteApiFp(this.configuration).updateNote(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return NoteApi;
}(base_1.BaseAPI));
exports.NoteApi = NoteApi;
/**
 * PotApi - axios parameter creator
 * @export
 */
exports.PotApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Destroys a single Pot resource.
         * @param {string} id The uuid of the Pot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyPot: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('destroyPot', 'id', id);
                    localVarPath = "/pots/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a listing of Pot resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexPot: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/pots";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single Pot resource.
         * @param {string} id The uuid of the Pot.
         * @param {PotPatch} body A single Pot resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPot: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('patchPot', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('patchPot', 'body', body);
                    localVarPath = "/pots/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single Pot resource by id.
         * @param {string} id The uuid of the Pot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPot: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showPot', 'id', id);
                    localVarPath = "/pots/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single Pot resource.
         * @param {PotEdit} body A single Pot resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePot: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('storePot', 'body', body);
                    localVarPath = "/pots";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Pot resource.
         * @param {string} id The uuid of the Pot.
         * @param {PotEdit} body A single Pot resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePot: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('updatePot', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('updatePot', 'body', body);
                    localVarPath = "/pots/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * PotApi - functional programming interface
 * @export
 */
exports.PotApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.PotApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Destroys a single Pot resource.
         * @param {string} id The uuid of the Pot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyPot: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.destroyPot(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a listing of Pot resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexPot: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexPot(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single Pot resource.
         * @param {string} id The uuid of the Pot.
         * @param {PotPatch} body A single Pot resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPot: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchPot(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single Pot resource by id.
         * @param {string} id The uuid of the Pot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPot: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showPot(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single Pot resource.
         * @param {PotEdit} body A single Pot resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePot: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.storePot(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Pot resource.
         * @param {string} id The uuid of the Pot.
         * @param {PotEdit} body A single Pot resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePot: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updatePot(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * PotApi - factory interface
 * @export
 */
exports.PotApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.PotApiFp(configuration);
    return {
        /**
         *
         * @summary Destroys a single Pot resource.
         * @param {string} id The uuid of the Pot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyPot: function (id, options) {
            return localVarFp.destroyPot(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a listing of Pot resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexPot: function (options) {
            return localVarFp.indexPot(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Modifies (parts of) a single Pot resource.
         * @param {string} id The uuid of the Pot.
         * @param {PotPatch} body A single Pot resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPot: function (id, body, options) {
            return localVarFp.patchPot(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single Pot resource by id.
         * @param {string} id The uuid of the Pot.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPot: function (id, options) {
            return localVarFp.showPot(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Creates a new instance of a single Pot resource.
         * @param {PotEdit} body A single Pot resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePot: function (body, options) {
            return localVarFp.storePot(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Pot resource.
         * @param {string} id The uuid of the Pot.
         * @param {PotEdit} body A single Pot resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePot: function (id, body, options) {
            return localVarFp.updatePot(id, body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PotApi - object-oriented interface
 * @export
 * @class PotApi
 * @extends {BaseAPI}
 */
var PotApi = /** @class */ (function (_super) {
    __extends(PotApi, _super);
    function PotApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Destroys a single Pot resource.
     * @param {string} id The uuid of the Pot.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotApi
     */
    PotApi.prototype.destroyPot = function (id, options) {
        var _this = this;
        return exports.PotApiFp(this.configuration).destroyPot(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a listing of Pot resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotApi
     */
    PotApi.prototype.indexPot = function (options) {
        var _this = this;
        return exports.PotApiFp(this.configuration).indexPot(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Modifies (parts of) a single Pot resource.
     * @param {string} id The uuid of the Pot.
     * @param {PotPatch} body A single Pot resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotApi
     */
    PotApi.prototype.patchPot = function (id, body, options) {
        var _this = this;
        return exports.PotApiFp(this.configuration).patchPot(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single Pot resource by id.
     * @param {string} id The uuid of the Pot.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotApi
     */
    PotApi.prototype.showPot = function (id, options) {
        var _this = this;
        return exports.PotApiFp(this.configuration).showPot(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Creates a new instance of a single Pot resource.
     * @param {PotEdit} body A single Pot resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotApi
     */
    PotApi.prototype.storePot = function (body, options) {
        var _this = this;
        return exports.PotApiFp(this.configuration).storePot(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Replaces (or updates all data of) a single Pot resource.
     * @param {string} id The uuid of the Pot.
     * @param {PotEdit} body A single Pot resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotApi
     */
    PotApi.prototype.updatePot = function (id, body, options) {
        var _this = this;
        return exports.PotApiFp(this.configuration).updatePot(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PotApi;
}(base_1.BaseAPI));
exports.PotApi = PotApi;
/**
 * PotGanttApi - axios parameter creator
 * @export
 */
exports.PotGanttApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Returns a listing of PotGantt resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexPotGantt: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/pot-gantts";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * PotGanttApi - functional programming interface
 * @export
 */
exports.PotGanttApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.PotGanttApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Returns a listing of PotGantt resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexPotGantt: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexPotGantt(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * PotGanttApi - factory interface
 * @export
 */
exports.PotGanttApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.PotGanttApiFp(configuration);
    return {
        /**
         *
         * @summary Returns a listing of PotGantt resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexPotGantt: function (options) {
            return localVarFp.indexPotGantt(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PotGanttApi - object-oriented interface
 * @export
 * @class PotGanttApi
 * @extends {BaseAPI}
 */
var PotGanttApi = /** @class */ (function (_super) {
    __extends(PotGanttApi, _super);
    function PotGanttApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Returns a listing of PotGantt resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotGanttApi
     */
    PotGanttApi.prototype.indexPotGantt = function (options) {
        var _this = this;
        return exports.PotGanttApiFp(this.configuration).indexPotGantt(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PotGanttApi;
}(base_1.BaseAPI));
exports.PotGanttApi = PotGanttApi;
/**
 * PotStatApi - axios parameter creator
 * @export
 */
exports.PotStatApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Returns a listing of PotStat resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexPotStat: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/pot-stats";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single PotStat resource by id.
         * @param {string} id The id of the pot to get statistics for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPotStat: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showPotStat', 'id', id);
                    localVarPath = "/pot-stats/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * PotStatApi - functional programming interface
 * @export
 */
exports.PotStatApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.PotStatApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Returns a listing of PotStat resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexPotStat: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexPotStat(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single PotStat resource by id.
         * @param {string} id The id of the pot to get statistics for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPotStat: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showPotStat(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * PotStatApi - factory interface
 * @export
 */
exports.PotStatApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.PotStatApiFp(configuration);
    return {
        /**
         *
         * @summary Returns a listing of PotStat resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexPotStat: function (options) {
            return localVarFp.indexPotStat(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single PotStat resource by id.
         * @param {string} id The id of the pot to get statistics for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPotStat: function (id, options) {
            return localVarFp.showPotStat(id, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PotStatApi - object-oriented interface
 * @export
 * @class PotStatApi
 * @extends {BaseAPI}
 */
var PotStatApi = /** @class */ (function (_super) {
    __extends(PotStatApi, _super);
    function PotStatApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Returns a listing of PotStat resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotStatApi
     */
    PotStatApi.prototype.indexPotStat = function (options) {
        var _this = this;
        return exports.PotStatApiFp(this.configuration).indexPotStat(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single PotStat resource by id.
     * @param {string} id The id of the pot to get statistics for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotStatApi
     */
    PotStatApi.prototype.showPotStat = function (id, options) {
        var _this = this;
        return exports.PotStatApiFp(this.configuration).showPotStat(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PotStatApi;
}(base_1.BaseAPI));
exports.PotStatApi = PotStatApi;
/**
 * PotTypeApi - axios parameter creator
 * @export
 */
exports.PotTypeApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Destroys a single PotType resource.
         * @param {number} id The id of the Pot Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyPotType: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('destroyPotType', 'id', id);
                    localVarPath = "/pot-types/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a listing of PotType resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexPotType: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/pot-types";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single PotType resource.
         * @param {number} id The id of the Pot Type.
         * @param {PotTypePatch} body A single PotType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPotType: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('patchPotType', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('patchPotType', 'body', body);
                    localVarPath = "/pot-types/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single PotType resource by id.
         * @param {number} id The id of the Pot Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPotType: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showPotType', 'id', id);
                    localVarPath = "/pot-types/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single PotType resource.
         * @param {PotTypeEdit} body A single PotType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePotType: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('storePotType', 'body', body);
                    localVarPath = "/pot-types";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single PotType resource.
         * @param {number} id The id of the Pot Type.
         * @param {PotTypeEdit} body A single PotType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePotType: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('updatePotType', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('updatePotType', 'body', body);
                    localVarPath = "/pot-types/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * PotTypeApi - functional programming interface
 * @export
 */
exports.PotTypeApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.PotTypeApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Destroys a single PotType resource.
         * @param {number} id The id of the Pot Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyPotType: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.destroyPotType(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a listing of PotType resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexPotType: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexPotType(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single PotType resource.
         * @param {number} id The id of the Pot Type.
         * @param {PotTypePatch} body A single PotType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPotType: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchPotType(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single PotType resource by id.
         * @param {number} id The id of the Pot Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPotType: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showPotType(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single PotType resource.
         * @param {PotTypeEdit} body A single PotType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePotType: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.storePotType(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single PotType resource.
         * @param {number} id The id of the Pot Type.
         * @param {PotTypeEdit} body A single PotType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePotType: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updatePotType(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * PotTypeApi - factory interface
 * @export
 */
exports.PotTypeApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.PotTypeApiFp(configuration);
    return {
        /**
         *
         * @summary Destroys a single PotType resource.
         * @param {number} id The id of the Pot Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyPotType: function (id, options) {
            return localVarFp.destroyPotType(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a listing of PotType resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexPotType: function (options) {
            return localVarFp.indexPotType(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Modifies (parts of) a single PotType resource.
         * @param {number} id The id of the Pot Type.
         * @param {PotTypePatch} body A single PotType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPotType: function (id, body, options) {
            return localVarFp.patchPotType(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single PotType resource by id.
         * @param {number} id The id of the Pot Type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showPotType: function (id, options) {
            return localVarFp.showPotType(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Creates a new instance of a single PotType resource.
         * @param {PotTypeEdit} body A single PotType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storePotType: function (body, options) {
            return localVarFp.storePotType(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single PotType resource.
         * @param {number} id The id of the Pot Type.
         * @param {PotTypeEdit} body A single PotType resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePotType: function (id, body, options) {
            return localVarFp.updatePotType(id, body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * PotTypeApi - object-oriented interface
 * @export
 * @class PotTypeApi
 * @extends {BaseAPI}
 */
var PotTypeApi = /** @class */ (function (_super) {
    __extends(PotTypeApi, _super);
    function PotTypeApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Destroys a single PotType resource.
     * @param {number} id The id of the Pot Type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotTypeApi
     */
    PotTypeApi.prototype.destroyPotType = function (id, options) {
        var _this = this;
        return exports.PotTypeApiFp(this.configuration).destroyPotType(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a listing of PotType resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotTypeApi
     */
    PotTypeApi.prototype.indexPotType = function (options) {
        var _this = this;
        return exports.PotTypeApiFp(this.configuration).indexPotType(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Modifies (parts of) a single PotType resource.
     * @param {number} id The id of the Pot Type.
     * @param {PotTypePatch} body A single PotType resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotTypeApi
     */
    PotTypeApi.prototype.patchPotType = function (id, body, options) {
        var _this = this;
        return exports.PotTypeApiFp(this.configuration).patchPotType(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single PotType resource by id.
     * @param {number} id The id of the Pot Type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotTypeApi
     */
    PotTypeApi.prototype.showPotType = function (id, options) {
        var _this = this;
        return exports.PotTypeApiFp(this.configuration).showPotType(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Creates a new instance of a single PotType resource.
     * @param {PotTypeEdit} body A single PotType resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotTypeApi
     */
    PotTypeApi.prototype.storePotType = function (body, options) {
        var _this = this;
        return exports.PotTypeApiFp(this.configuration).storePotType(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Replaces (or updates all data of) a single PotType resource.
     * @param {number} id The id of the Pot Type.
     * @param {PotTypeEdit} body A single PotType resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PotTypeApi
     */
    PotTypeApi.prototype.updatePotType = function (id, body, options) {
        var _this = this;
        return exports.PotTypeApiFp(this.configuration).updatePotType(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PotTypeApi;
}(base_1.BaseAPI));
exports.PotTypeApi = PotTypeApi;
/**
 * ProfileApi - axios parameter creator
 * @export
 */
exports.ProfileApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Returns a listing of Profile resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexProfile: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/profiles";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single Profile resource by id.
         * @param {number} id The id of the Profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showProfile: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showProfile', 'id', id);
                    localVarPath = "/profiles/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * ProfileApi - functional programming interface
 * @export
 */
exports.ProfileApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ProfileApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Returns a listing of Profile resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexProfile: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexProfile(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single Profile resource by id.
         * @param {number} id The id of the Profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showProfile: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showProfile(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ProfileApi - factory interface
 * @export
 */
exports.ProfileApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ProfileApiFp(configuration);
    return {
        /**
         *
         * @summary Returns a listing of Profile resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexProfile: function (options) {
            return localVarFp.indexProfile(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single Profile resource by id.
         * @param {number} id The id of the Profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showProfile: function (id, options) {
            return localVarFp.showProfile(id, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
var ProfileApi = /** @class */ (function (_super) {
    __extends(ProfileApi, _super);
    function ProfileApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Returns a listing of Profile resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    ProfileApi.prototype.indexProfile = function (options) {
        var _this = this;
        return exports.ProfileApiFp(this.configuration).indexProfile(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single Profile resource by id.
     * @param {number} id The id of the Profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    ProfileApi.prototype.showProfile = function (id, options) {
        var _this = this;
        return exports.ProfileApiFp(this.configuration).showProfile(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ProfileApi;
}(base_1.BaseAPI));
exports.ProfileApi = ProfileApi;
/**
 * SalaryBandApi - axios parameter creator
 * @export
 */
exports.SalaryBandApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Destroys a single SalaryBand resource.
         * @param {number} id The id of the salary band.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySalaryBand: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('destroySalaryBand', 'id', id);
                    localVarPath = "/salary-bands/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a listing of SalaryBand resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSalaryBand: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/salary-bands";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single SalaryBand resource.
         * @param {number} id The id of the salary band.
         * @param {SalaryBandPatch} body A single SalaryBand resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSalaryBand: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('patchSalaryBand', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('patchSalaryBand', 'body', body);
                    localVarPath = "/salary-bands/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single SalaryBand resource by id.
         * @param {number} id The id of the salary band.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSalaryBand: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showSalaryBand', 'id', id);
                    localVarPath = "/salary-bands/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single SalaryBand resource.
         * @param {SalaryBandEdit} body A single SalaryBand resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSalaryBand: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('storeSalaryBand', 'body', body);
                    localVarPath = "/salary-bands";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single SalaryBand resource.
         * @param {number} id The id of the salary band.
         * @param {SalaryBandEdit} body A single SalaryBand resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSalaryBand: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('updateSalaryBand', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('updateSalaryBand', 'body', body);
                    localVarPath = "/salary-bands/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * SalaryBandApi - functional programming interface
 * @export
 */
exports.SalaryBandApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.SalaryBandApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Destroys a single SalaryBand resource.
         * @param {number} id The id of the salary band.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySalaryBand: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.destroySalaryBand(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a listing of SalaryBand resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSalaryBand: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexSalaryBand(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single SalaryBand resource.
         * @param {number} id The id of the salary band.
         * @param {SalaryBandPatch} body A single SalaryBand resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSalaryBand: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchSalaryBand(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single SalaryBand resource by id.
         * @param {number} id The id of the salary band.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSalaryBand: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showSalaryBand(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single SalaryBand resource.
         * @param {SalaryBandEdit} body A single SalaryBand resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSalaryBand: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.storeSalaryBand(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single SalaryBand resource.
         * @param {number} id The id of the salary band.
         * @param {SalaryBandEdit} body A single SalaryBand resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSalaryBand: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSalaryBand(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * SalaryBandApi - factory interface
 * @export
 */
exports.SalaryBandApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.SalaryBandApiFp(configuration);
    return {
        /**
         *
         * @summary Destroys a single SalaryBand resource.
         * @param {number} id The id of the salary band.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySalaryBand: function (id, options) {
            return localVarFp.destroySalaryBand(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a listing of SalaryBand resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSalaryBand: function (options) {
            return localVarFp.indexSalaryBand(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Modifies (parts of) a single SalaryBand resource.
         * @param {number} id The id of the salary band.
         * @param {SalaryBandPatch} body A single SalaryBand resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSalaryBand: function (id, body, options) {
            return localVarFp.patchSalaryBand(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single SalaryBand resource by id.
         * @param {number} id The id of the salary band.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSalaryBand: function (id, options) {
            return localVarFp.showSalaryBand(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Creates a new instance of a single SalaryBand resource.
         * @param {SalaryBandEdit} body A single SalaryBand resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSalaryBand: function (body, options) {
            return localVarFp.storeSalaryBand(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single SalaryBand resource.
         * @param {number} id The id of the salary band.
         * @param {SalaryBandEdit} body A single SalaryBand resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSalaryBand: function (id, body, options) {
            return localVarFp.updateSalaryBand(id, body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * SalaryBandApi - object-oriented interface
 * @export
 * @class SalaryBandApi
 * @extends {BaseAPI}
 */
var SalaryBandApi = /** @class */ (function (_super) {
    __extends(SalaryBandApi, _super);
    function SalaryBandApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Destroys a single SalaryBand resource.
     * @param {number} id The id of the salary band.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryBandApi
     */
    SalaryBandApi.prototype.destroySalaryBand = function (id, options) {
        var _this = this;
        return exports.SalaryBandApiFp(this.configuration).destroySalaryBand(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a listing of SalaryBand resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryBandApi
     */
    SalaryBandApi.prototype.indexSalaryBand = function (options) {
        var _this = this;
        return exports.SalaryBandApiFp(this.configuration).indexSalaryBand(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Modifies (parts of) a single SalaryBand resource.
     * @param {number} id The id of the salary band.
     * @param {SalaryBandPatch} body A single SalaryBand resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryBandApi
     */
    SalaryBandApi.prototype.patchSalaryBand = function (id, body, options) {
        var _this = this;
        return exports.SalaryBandApiFp(this.configuration).patchSalaryBand(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single SalaryBand resource by id.
     * @param {number} id The id of the salary band.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryBandApi
     */
    SalaryBandApi.prototype.showSalaryBand = function (id, options) {
        var _this = this;
        return exports.SalaryBandApiFp(this.configuration).showSalaryBand(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Creates a new instance of a single SalaryBand resource.
     * @param {SalaryBandEdit} body A single SalaryBand resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryBandApi
     */
    SalaryBandApi.prototype.storeSalaryBand = function (body, options) {
        var _this = this;
        return exports.SalaryBandApiFp(this.configuration).storeSalaryBand(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Replaces (or updates all data of) a single SalaryBand resource.
     * @param {number} id The id of the salary band.
     * @param {SalaryBandEdit} body A single SalaryBand resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalaryBandApi
     */
    SalaryBandApi.prototype.updateSalaryBand = function (id, body, options) {
        var _this = this;
        return exports.SalaryBandApiFp(this.configuration).updateSalaryBand(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SalaryBandApi;
}(base_1.BaseAPI));
exports.SalaryBandApi = SalaryBandApi;
/**
 * ScheduleApi - axios parameter creator
 * @export
 */
exports.ScheduleApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Returns a listing of Schedule resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSchedule: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/schedules";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * ScheduleApi - functional programming interface
 * @export
 */
exports.ScheduleApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ScheduleApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Returns a listing of Schedule resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSchedule: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexSchedule(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ScheduleApi - factory interface
 * @export
 */
exports.ScheduleApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ScheduleApiFp(configuration);
    return {
        /**
         *
         * @summary Returns a listing of Schedule resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSchedule: function (options) {
            return localVarFp.indexSchedule(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ScheduleApi - object-oriented interface
 * @export
 * @class ScheduleApi
 * @extends {BaseAPI}
 */
var ScheduleApi = /** @class */ (function (_super) {
    __extends(ScheduleApi, _super);
    function ScheduleApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Returns a listing of Schedule resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleApi
     */
    ScheduleApi.prototype.indexSchedule = function (options) {
        var _this = this;
        return exports.ScheduleApiFp(this.configuration).indexSchedule(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ScheduleApi;
}(base_1.BaseAPI));
exports.ScheduleApi = ScheduleApi;
/**
 * SearchApi - axios parameter creator
 * @export
 */
exports.SearchApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Returns a listing of Search resources with filtering and pagination support.
         * @param {string} query A query string for searching by tags or project names.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSearch: function (query, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'query' is not null or undefined
                    common_1.assertParamExists('indexSearch', 'query', query);
                    localVarPath = "/search";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (query !== undefined) {
                        localVarQueryParameter['query'] = query;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * SearchApi - functional programming interface
 * @export
 */
exports.SearchApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.SearchApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Returns a listing of Search resources with filtering and pagination support.
         * @param {string} query A query string for searching by tags or project names.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSearch: function (query, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexSearch(query, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * SearchApi - factory interface
 * @export
 */
exports.SearchApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.SearchApiFp(configuration);
    return {
        /**
         *
         * @summary Returns a listing of Search resources with filtering and pagination support.
         * @param {string} query A query string for searching by tags or project names.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSearch: function (query, options) {
            return localVarFp.indexSearch(query, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
var SearchApi = /** @class */ (function (_super) {
    __extends(SearchApi, _super);
    function SearchApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Returns a listing of Search resources with filtering and pagination support.
     * @param {string} query A query string for searching by tags or project names.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    SearchApi.prototype.indexSearch = function (query, options) {
        var _this = this;
        return exports.SearchApiFp(this.configuration).indexSearch(query, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SearchApi;
}(base_1.BaseAPI));
exports.SearchApi = SearchApi;
/**
 * SkillApi - axios parameter creator
 * @export
 */
exports.SkillApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Destroys a single Skill resource.
         * @param {number} id The id of the Skill.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySkill: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('destroySkill', 'id', id);
                    localVarPath = "/skills/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a listing of Skill resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSkill: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/skills";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single Skill resource.
         * @param {number} id The id of the Skill.
         * @param {SkillPatch} body A single Skill resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSkill: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('patchSkill', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('patchSkill', 'body', body);
                    localVarPath = "/skills/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single Skill resource by id.
         * @param {number} id The id of the Skill.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSkill: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showSkill', 'id', id);
                    localVarPath = "/skills/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single Skill resource.
         * @param {SkillEdit} body A single Skill resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSkill: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('storeSkill', 'body', body);
                    localVarPath = "/skills";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Skill resource.
         * @param {number} id The id of the Skill.
         * @param {SkillEdit} body A single Skill resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkill: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('updateSkill', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('updateSkill', 'body', body);
                    localVarPath = "/skills/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * SkillApi - functional programming interface
 * @export
 */
exports.SkillApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.SkillApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Destroys a single Skill resource.
         * @param {number} id The id of the Skill.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySkill: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.destroySkill(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a listing of Skill resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSkill: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexSkill(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single Skill resource.
         * @param {number} id The id of the Skill.
         * @param {SkillPatch} body A single Skill resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSkill: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchSkill(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single Skill resource by id.
         * @param {number} id The id of the Skill.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSkill: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showSkill(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single Skill resource.
         * @param {SkillEdit} body A single Skill resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSkill: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.storeSkill(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Skill resource.
         * @param {number} id The id of the Skill.
         * @param {SkillEdit} body A single Skill resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkill: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSkill(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * SkillApi - factory interface
 * @export
 */
exports.SkillApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.SkillApiFp(configuration);
    return {
        /**
         *
         * @summary Destroys a single Skill resource.
         * @param {number} id The id of the Skill.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroySkill: function (id, options) {
            return localVarFp.destroySkill(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a listing of Skill resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSkill: function (options) {
            return localVarFp.indexSkill(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Modifies (parts of) a single Skill resource.
         * @param {number} id The id of the Skill.
         * @param {SkillPatch} body A single Skill resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSkill: function (id, body, options) {
            return localVarFp.patchSkill(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single Skill resource by id.
         * @param {number} id The id of the Skill.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSkill: function (id, options) {
            return localVarFp.showSkill(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Creates a new instance of a single Skill resource.
         * @param {SkillEdit} body A single Skill resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSkill: function (body, options) {
            return localVarFp.storeSkill(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Skill resource.
         * @param {number} id The id of the Skill.
         * @param {SkillEdit} body A single Skill resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSkill: function (id, body, options) {
            return localVarFp.updateSkill(id, body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * SkillApi - object-oriented interface
 * @export
 * @class SkillApi
 * @extends {BaseAPI}
 */
var SkillApi = /** @class */ (function (_super) {
    __extends(SkillApi, _super);
    function SkillApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Destroys a single Skill resource.
     * @param {number} id The id of the Skill.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    SkillApi.prototype.destroySkill = function (id, options) {
        var _this = this;
        return exports.SkillApiFp(this.configuration).destroySkill(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a listing of Skill resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    SkillApi.prototype.indexSkill = function (options) {
        var _this = this;
        return exports.SkillApiFp(this.configuration).indexSkill(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Modifies (parts of) a single Skill resource.
     * @param {number} id The id of the Skill.
     * @param {SkillPatch} body A single Skill resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    SkillApi.prototype.patchSkill = function (id, body, options) {
        var _this = this;
        return exports.SkillApiFp(this.configuration).patchSkill(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single Skill resource by id.
     * @param {number} id The id of the Skill.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    SkillApi.prototype.showSkill = function (id, options) {
        var _this = this;
        return exports.SkillApiFp(this.configuration).showSkill(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Creates a new instance of a single Skill resource.
     * @param {SkillEdit} body A single Skill resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    SkillApi.prototype.storeSkill = function (body, options) {
        var _this = this;
        return exports.SkillApiFp(this.configuration).storeSkill(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Replaces (or updates all data of) a single Skill resource.
     * @param {number} id The id of the Skill.
     * @param {SkillEdit} body A single Skill resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    SkillApi.prototype.updateSkill = function (id, body, options) {
        var _this = this;
        return exports.SkillApiFp(this.configuration).updateSkill(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SkillApi;
}(base_1.BaseAPI));
exports.SkillApi = SkillApi;
/**
 * TagApi - axios parameter creator
 * @export
 */
exports.TagApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Destroys a single Tag resource.
         * @param {number} id The id of the Tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyTag: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('destroyTag', 'id', id);
                    localVarPath = "/tags/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a listing of Tag resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexTag: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/tags";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single Tag resource.
         * @param {number} id The id of the Tag.
         * @param {TagPatch} body A single Tag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTag: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('patchTag', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('patchTag', 'body', body);
                    localVarPath = "/tags/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single Tag resource by id.
         * @param {number} id The id of the Tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showTag: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showTag', 'id', id);
                    localVarPath = "/tags/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single Tag resource.
         * @param {TagEdit} body A single Tag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeTag: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('storeTag', 'body', body);
                    localVarPath = "/tags";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Tag resource.
         * @param {number} id The id of the Tag.
         * @param {TagEdit} body A single Tag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('updateTag', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('updateTag', 'body', body);
                    localVarPath = "/tags/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * TagApi - functional programming interface
 * @export
 */
exports.TagApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.TagApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Destroys a single Tag resource.
         * @param {number} id The id of the Tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyTag: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.destroyTag(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a listing of Tag resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexTag: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexTag(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single Tag resource.
         * @param {number} id The id of the Tag.
         * @param {TagPatch} body A single Tag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTag: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchTag(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single Tag resource by id.
         * @param {number} id The id of the Tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showTag: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showTag(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single Tag resource.
         * @param {TagEdit} body A single Tag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeTag: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.storeTag(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Tag resource.
         * @param {number} id The id of the Tag.
         * @param {TagEdit} body A single Tag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateTag(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * TagApi - factory interface
 * @export
 */
exports.TagApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.TagApiFp(configuration);
    return {
        /**
         *
         * @summary Destroys a single Tag resource.
         * @param {number} id The id of the Tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyTag: function (id, options) {
            return localVarFp.destroyTag(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a listing of Tag resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexTag: function (options) {
            return localVarFp.indexTag(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Modifies (parts of) a single Tag resource.
         * @param {number} id The id of the Tag.
         * @param {TagPatch} body A single Tag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTag: function (id, body, options) {
            return localVarFp.patchTag(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single Tag resource by id.
         * @param {number} id The id of the Tag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showTag: function (id, options) {
            return localVarFp.showTag(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Creates a new instance of a single Tag resource.
         * @param {TagEdit} body A single Tag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeTag: function (body, options) {
            return localVarFp.storeTag(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single Tag resource.
         * @param {number} id The id of the Tag.
         * @param {TagEdit} body A single Tag resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: function (id, body, options) {
            return localVarFp.updateTag(id, body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
var TagApi = /** @class */ (function (_super) {
    __extends(TagApi, _super);
    function TagApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Destroys a single Tag resource.
     * @param {number} id The id of the Tag.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    TagApi.prototype.destroyTag = function (id, options) {
        var _this = this;
        return exports.TagApiFp(this.configuration).destroyTag(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a listing of Tag resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    TagApi.prototype.indexTag = function (options) {
        var _this = this;
        return exports.TagApiFp(this.configuration).indexTag(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Modifies (parts of) a single Tag resource.
     * @param {number} id The id of the Tag.
     * @param {TagPatch} body A single Tag resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    TagApi.prototype.patchTag = function (id, body, options) {
        var _this = this;
        return exports.TagApiFp(this.configuration).patchTag(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single Tag resource by id.
     * @param {number} id The id of the Tag.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    TagApi.prototype.showTag = function (id, options) {
        var _this = this;
        return exports.TagApiFp(this.configuration).showTag(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Creates a new instance of a single Tag resource.
     * @param {TagEdit} body A single Tag resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    TagApi.prototype.storeTag = function (body, options) {
        var _this = this;
        return exports.TagApiFp(this.configuration).storeTag(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Replaces (or updates all data of) a single Tag resource.
     * @param {number} id The id of the Tag.
     * @param {TagEdit} body A single Tag resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    TagApi.prototype.updateTag = function (id, body, options) {
        var _this = this;
        return exports.TagApiFp(this.configuration).updateTag(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TagApi;
}(base_1.BaseAPI));
exports.TagApi = TagApi;
/**
 * UserApi - axios parameter creator
 * @export
 */
exports.UserApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Destroys a single User resource.
         * @param {string} id The uuid of the User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyUser: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('destroyUser', 'id', id);
                    localVarPath = "/users/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a listing of User resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexUser: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/users";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single User resource.
         * @param {string} id The uuid of the User.
         * @param {UserPatch} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('patchUser', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('patchUser', 'body', body);
                    localVarPath = "/users/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single User resource by id.
         * @param {string} id The uuid of the User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUser: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showUser', 'id', id);
                    localVarPath = "/users/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single User resource.
         * @param {UserEdit} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeUser: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('storeUser', 'body', body);
                    localVarPath = "/users";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single User resource.
         * @param {string} id The uuid of the User.
         * @param {UserEdit} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('updateUser', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('updateUser', 'body', body);
                    localVarPath = "/users/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * UserApi - functional programming interface
 * @export
 */
exports.UserApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.UserApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Destroys a single User resource.
         * @param {string} id The uuid of the User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyUser: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.destroyUser(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a listing of User resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexUser: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexUser(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single User resource.
         * @param {string} id The uuid of the User.
         * @param {UserPatch} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patchUser(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single User resource by id.
         * @param {string} id The uuid of the User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUser: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showUser(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single User resource.
         * @param {UserEdit} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeUser: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.storeUser(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single User resource.
         * @param {string} id The uuid of the User.
         * @param {UserEdit} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateUser(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * UserApi - factory interface
 * @export
 */
exports.UserApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.UserApiFp(configuration);
    return {
        /**
         *
         * @summary Destroys a single User resource.
         * @param {string} id The uuid of the User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyUser: function (id, options) {
            return localVarFp.destroyUser(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a listing of User resources with filtering and pagination support.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexUser: function (options) {
            return localVarFp.indexUser(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Modifies (parts of) a single User resource.
         * @param {string} id The uuid of the User.
         * @param {UserPatch} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser: function (id, body, options) {
            return localVarFp.patchUser(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single User resource by id.
         * @param {string} id The uuid of the User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUser: function (id, options) {
            return localVarFp.showUser(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Creates a new instance of a single User resource.
         * @param {UserEdit} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeUser: function (body, options) {
            return localVarFp.storeUser(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single User resource.
         * @param {string} id The uuid of the User.
         * @param {UserEdit} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: function (id, body, options) {
            return localVarFp.updateUser(id, body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
var UserApi = /** @class */ (function (_super) {
    __extends(UserApi, _super);
    function UserApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Destroys a single User resource.
     * @param {string} id The uuid of the User.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.destroyUser = function (id, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).destroyUser(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a listing of User resources with filtering and pagination support.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.indexUser = function (options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).indexUser(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Modifies (parts of) a single User resource.
     * @param {string} id The uuid of the User.
     * @param {UserPatch} body A single User resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.patchUser = function (id, body, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).patchUser(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single User resource by id.
     * @param {string} id The uuid of the User.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.showUser = function (id, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).showUser(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Creates a new instance of a single User resource.
     * @param {UserEdit} body A single User resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.storeUser = function (body, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).storeUser(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Replaces (or updates all data of) a single User resource.
     * @param {string} id The uuid of the User.
     * @param {UserEdit} body A single User resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.updateUser = function (id, body, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).updateUser(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UserApi;
}(base_1.BaseAPI));
exports.UserApi = UserApi;
