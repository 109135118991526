import { QueryFunctionContext, useQuery } from 'react-query';
import { api } from 'core/services/api.service';
import { statisticsKeys } from 'statistics/queries/statistics.keys';

type IndexStatistics = ReturnType<typeof statisticsKeys['indexTicketStats']>;

const indexTicketStats = async ({
  queryKey: [{ potTypeName, selectedPotName, pageNumber }],
}: QueryFunctionContext<IndexStatistics>) => {
  let queryParams = {};

  // return all tickets
  if (potTypeName === 'ticket' || selectedPotName !== '') {
    queryParams = {
      'pot_type@name': potTypeName,
    };
  }

  // return one ticket
  if (potTypeName === 'ticket' && selectedPotName !== '') {
    queryParams = {
      'pot_type@name': potTypeName,
      label: selectedPotName,
    };
  }

  // return tickets related to selected project / tag
  if (potTypeName === 'project' || potTypeName === 'tag') {
    queryParams = {
      'pot_type@name': 'ticket',
      'tags,parent_pots@name': selectedPotName,
    };
  }

  const { data } = await api.potsService.indexTaskmanagerPot({
    params: {
      limit: 21,
      page: pageNumber,
      ...queryParams,
    },
  });
  return data;
};

export const useIndexTicketStats = (queryKey: IndexStatistics) => {
  const [{ enabled }] = queryKey;

  return useQuery(queryKey, indexTicketStats, {
    enabled,
  });
};
