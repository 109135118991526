"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Taskmanager SSO Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = exports.StatusApi = exports.StatusApiFactory = exports.StatusApiFp = exports.StatusApiAxiosParamCreator = exports.AuthResultApi = exports.AuthResultApiFactory = exports.AuthResultApiFp = exports.AuthResultApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 * AuthResultApi - axios parameter creator
 * @export
 */
exports.AuthResultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Creates a new Auth Bearer JWT.
         * @param {AuthRequest} body A single AuthRequest resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('auth', 'body', body);
                    localVarPath = "/auth";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * AuthResultApi - functional programming interface
 * @export
 */
exports.AuthResultApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.AuthResultApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Creates a new Auth Bearer JWT.
         * @param {AuthRequest} body A single AuthRequest resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.auth(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * AuthResultApi - factory interface
 * @export
 */
exports.AuthResultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.AuthResultApiFp(configuration);
    return {
        /**
         *
         * @summary Creates a new Auth Bearer JWT.
         * @param {AuthRequest} body A single AuthRequest resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth: function (body, options) {
            return localVarFp.auth(body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * AuthResultApi - object-oriented interface
 * @export
 * @class AuthResultApi
 * @extends {BaseAPI}
 */
var AuthResultApi = /** @class */ (function (_super) {
    __extends(AuthResultApi, _super);
    function AuthResultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Creates a new Auth Bearer JWT.
     * @param {AuthRequest} body A single AuthRequest resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthResultApi
     */
    AuthResultApi.prototype.auth = function (body, options) {
        var _this = this;
        return exports.AuthResultApiFp(this.configuration).auth(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AuthResultApi;
}(base_1.BaseAPI));
exports.AuthResultApi = AuthResultApi;
/**
 * StatusApi - axios parameter creator
 * @export
 */
exports.StatusApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Takes a ForgotRequest with information about the user to reset the password of.
         * @param {ForgotRequest} body A single ForgotRequest resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('forgotPassword', 'body', body);
                    localVarPath = "/forgot-password";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Resets a password using a token provided by the forgot password email.
         * @param {ResetRequest} body A single ResetRequest resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('resetPassword', 'body', body);
                    localVarPath = "/reset-password";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Revokes an Auth Bearer JWT.
         * @param {AuthRequest} body A single AuthRequest resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('revokeToken', 'body', body);
                    localVarPath = "/revoke";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * StatusApi - functional programming interface
 * @export
 */
exports.StatusApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.StatusApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Takes a ForgotRequest with information about the user to reset the password of.
         * @param {ForgotRequest} body A single ForgotRequest resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.forgotPassword(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Resets a password using a token provided by the forgot password email.
         * @param {ResetRequest} body A single ResetRequest resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.resetPassword(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Revokes an Auth Bearer JWT.
         * @param {AuthRequest} body A single AuthRequest resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.revokeToken(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * StatusApi - factory interface
 * @export
 */
exports.StatusApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.StatusApiFp(configuration);
    return {
        /**
         *
         * @summary Takes a ForgotRequest with information about the user to reset the password of.
         * @param {ForgotRequest} body A single ForgotRequest resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: function (body, options) {
            return localVarFp.forgotPassword(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Resets a password using a token provided by the forgot password email.
         * @param {ResetRequest} body A single ResetRequest resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: function (body, options) {
            return localVarFp.resetPassword(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Revokes an Auth Bearer JWT.
         * @param {AuthRequest} body A single AuthRequest resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken: function (body, options) {
            return localVarFp.revokeToken(body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
var StatusApi = /** @class */ (function (_super) {
    __extends(StatusApi, _super);
    function StatusApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Takes a ForgotRequest with information about the user to reset the password of.
     * @param {ForgotRequest} body A single ForgotRequest resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    StatusApi.prototype.forgotPassword = function (body, options) {
        var _this = this;
        return exports.StatusApiFp(this.configuration).forgotPassword(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Resets a password using a token provided by the forgot password email.
     * @param {ResetRequest} body A single ResetRequest resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    StatusApi.prototype.resetPassword = function (body, options) {
        var _this = this;
        return exports.StatusApiFp(this.configuration).resetPassword(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Revokes an Auth Bearer JWT.
     * @param {AuthRequest} body A single AuthRequest resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    StatusApi.prototype.revokeToken = function (body, options) {
        var _this = this;
        return exports.StatusApiFp(this.configuration).revokeToken(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return StatusApi;
}(base_1.BaseAPI));
exports.StatusApi = StatusApi;
/**
 * UserApi - axios parameter creator
 * @export
 */
exports.UserApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Clones an existing instance of a single User resource with optional fields replaced.
         * @param {string} id The uuid of the User.
         * @param {UserPartialDto} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneUser: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('cloneUser', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('cloneUser', 'body', body);
                    localVarPath = "/users/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Destroys a single User resource.
         * @param {string} id The uuid of the User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyUser: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('destroyUser', 'id', id);
                    localVarPath = "/users/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a listing of User resources with filtering and pagination support.
         * @param {string} [username] The username to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexUser: function (username, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/users";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (username !== undefined) {
                        localVarQueryParameter['username'] = username;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single User resource.
         * @param {string} id The uuid of the User.
         * @param {UserPartialDto} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUser: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('modifyUser', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('modifyUser', 'body', body);
                    localVarPath = "/users/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single User resource.
         * @param {string} id The uuid of the User.
         * @param {UserRequestDto} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceUser: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('replaceUser', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('replaceUser', 'body', body);
                    localVarPath = "/users/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a single User resource by id.
         * @param {string} id The uuid of the User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUser: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('showUser', 'id', id);
                    localVarPath = "/users/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single User resource.
         * @param {UserRequestDto} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeUser: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('storeUser', 'body', body);
                    localVarPath = "/users";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * UserApi - functional programming interface
 * @export
 */
exports.UserApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.UserApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Clones an existing instance of a single User resource with optional fields replaced.
         * @param {string} id The uuid of the User.
         * @param {UserPartialDto} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneUser: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.cloneUser(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Destroys a single User resource.
         * @param {string} id The uuid of the User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyUser: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.destroyUser(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a listing of User resources with filtering and pagination support.
         * @param {string} [username] The username to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexUser: function (username, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.indexUser(username, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Modifies (parts of) a single User resource.
         * @param {string} id The uuid of the User.
         * @param {UserPartialDto} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUser: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.modifyUser(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single User resource.
         * @param {string} id The uuid of the User.
         * @param {UserRequestDto} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceUser: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.replaceUser(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a single User resource by id.
         * @param {string} id The uuid of the User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUser: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.showUser(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Creates a new instance of a single User resource.
         * @param {UserRequestDto} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeUser: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.storeUser(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * UserApi - factory interface
 * @export
 */
exports.UserApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.UserApiFp(configuration);
    return {
        /**
         *
         * @summary Clones an existing instance of a single User resource with optional fields replaced.
         * @param {string} id The uuid of the User.
         * @param {UserPartialDto} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneUser: function (id, body, options) {
            return localVarFp.cloneUser(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Destroys a single User resource.
         * @param {string} id The uuid of the User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyUser: function (id, options) {
            return localVarFp.destroyUser(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a listing of User resources with filtering and pagination support.
         * @param {string} [username] The username to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexUser: function (username, options) {
            return localVarFp.indexUser(username, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Modifies (parts of) a single User resource.
         * @param {string} id The uuid of the User.
         * @param {UserPartialDto} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUser: function (id, body, options) {
            return localVarFp.modifyUser(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Replaces (or updates all data of) a single User resource.
         * @param {string} id The uuid of the User.
         * @param {UserRequestDto} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceUser: function (id, body, options) {
            return localVarFp.replaceUser(id, body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a single User resource by id.
         * @param {string} id The uuid of the User.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUser: function (id, options) {
            return localVarFp.showUser(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Creates a new instance of a single User resource.
         * @param {UserRequestDto} body A single User resource.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeUser: function (body, options) {
            return localVarFp.storeUser(body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
var UserApi = /** @class */ (function (_super) {
    __extends(UserApi, _super);
    function UserApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Clones an existing instance of a single User resource with optional fields replaced.
     * @param {string} id The uuid of the User.
     * @param {UserPartialDto} body A single User resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.cloneUser = function (id, body, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).cloneUser(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Destroys a single User resource.
     * @param {string} id The uuid of the User.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.destroyUser = function (id, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).destroyUser(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a listing of User resources with filtering and pagination support.
     * @param {string} [username] The username to filter by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.indexUser = function (username, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).indexUser(username, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Modifies (parts of) a single User resource.
     * @param {string} id The uuid of the User.
     * @param {UserPartialDto} body A single User resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.modifyUser = function (id, body, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).modifyUser(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Replaces (or updates all data of) a single User resource.
     * @param {string} id The uuid of the User.
     * @param {UserRequestDto} body A single User resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.replaceUser = function (id, body, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).replaceUser(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a single User resource by id.
     * @param {string} id The uuid of the User.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.showUser = function (id, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).showUser(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Creates a new instance of a single User resource.
     * @param {UserRequestDto} body A single User resource.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    UserApi.prototype.storeUser = function (body, options) {
        var _this = this;
        return exports.UserApiFp(this.configuration).storeUser(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return UserApi;
}(base_1.BaseAPI));
exports.UserApi = UserApi;
