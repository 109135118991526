import useTagSearch from 'gantt/hooks/gant-view/use-tag-search';
import { Dispatch, SetStateAction } from 'react';
import TaggingSearch, {
  ISearchOption,
} from 'core/components/tagging-search/tagging-search';
import { TaskmanagerSearchReturnDto } from 'gateway-api';

interface ISearchTagProps {
  setTags: Dispatch<SetStateAction<string[]>>;
}

const GantSearch = ({ setTags }: ISearchTagProps): JSX.Element => {
  const [search, { data }] = useTagSearch();

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: TaskmanagerSearchReturnDto[],
  ) => {
    if (e.code === 'Enter') {
      e.stopPropagation();
    }
  };

  return (
    <TaggingSearch
      showTags={false}
      freeSolo
      onChange={(_, __, ___, details) => {
        if (details?.option.label) {
          setTags((prevState) => [...prevState, details?.option.label]);
        }
      }}
      // Stop enter from loosing focus. It doesn't need to search.
      onKeyDown={handleKeyDown}
      options={
        data?.map((item) => item?.label as unknown as ISearchOption) ?? []
      }
      onInputChange={(event, value) => {
        search(value);
      }}
    />
  );
};

export default GantSearch;
